import { action, cache } from "@solidjs/router";
import { googleSignIn_, login_, regenerateJWT_, register_, signOut_ } from "./auth";
import { getApiKey_, getJWT_, getUser_ } from "~/lib/session";
import { verifyJWT_ } from "./jwt";
import { getShortUrl } from "./url";

export const login = action(login_, "login");
export const googleSignInAction = action(googleSignIn_, "googleSignIn");
export const register = action(register_, "register");
export const signOut = action(signOut_, "signOut");

export const getSessionUser = cache(async () => {
  "use server";
  const user = await getUser_();
  return user;
}, "getUser");

export const getJWT = action(async () => {
  "use server";
  const jwt = await getJWT_();

  return jwt;
}, "getJWT");

export const jwtCache = cache(async () => {
  "use server";
  const jwt = await getJWT_();
  return jwt;
}, "jwt");

export const getApiKey = action(async () => {
  "use server";
  const apiKey = await getApiKey_();

  return apiKey;
}, "getApiKey");

export const apiKeyCache = cache(async () => {
  "use server";
  const apiKey = await getApiKey_();

  return apiKey;
}, "getApiKey");

export const verifyJWT = action(async (token) => {
  "use server";
  try {
    return verifyJWT_(token);
  } catch (error) {
    throw error;
  }
}, "verifyJWT");

export const regenerateJWTAction = action(regenerateJWT_, "regenrateJWT");

export const getShortUrlAction = action(getShortUrl, "getShortUrl");
