"use server";

export async function getShortUrl(data: string): Promise<string> {
  const domain = "playground.julep.ai";
  const originalURL = `https://${domain}/?data=${data}`;

  try {
    const response = await fetch(`https://api.short.io/links`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        authorization: "sk_6azXMV7gGWEkk0kL",
      },
      body: JSON.stringify({
        domain,
        originalURL,
      }),
    });

    const resData = await response.json();

    const shortUrl = resData.shortURL.split("/").pop();

    return shortUrl;
  } catch (error) {
    throw new Error("Error fetching short url");
  }
}
