"use server";
import { redirect } from "@solidjs/router";
import { parseCookies, useSession } from "vinxi/http";
import { getRequestEvent } from "solid-js/web";
import { SupabaseClient, createClient } from "@supabase/supabase-js";
import qs from "query-string";

import { generateJWT } from "./jwt";
import getLongUrl from "~/utils/getLongUrl";

type LoginForm = {
  email: string;
  password: string;
};

type UpdateForm = {
  name?: string;
  age?: number;
  gender?: string;
  about?: string;
  memoryId?: string;
  onboarded?: boolean;
  principlesFinished?: boolean;
};

// supabase.auth.onAuthStateChange(async (event, session) => {
//   const clientSession = await getSession();
//   if (session && session.provider_token) {
//     console.log({ session });
//     await clientSession.update((d) => (d.provider_token = session.provider_token));
//   }

//   if (session && session.provider_refresh_token) {
//     await clientSession.update((d) => (d.provider_refresh_token = session.provider_refresh_token));
//   }

//   if (event === "SIGNED_OUT") {
//     await clientSession.update((d) => {
//       d.provider_token = undefined;
//       d.provider_refresh_token = undefined;
//     });
//   }
// });

export function getSession() {
  return useSession(getRequestEvent()!, {
    password: process.env.SESSION_SECRET ?? "areallylongsecretthatyoushouldreplace",
  });
}

export async function getCookies() {
  return parseCookies(getRequestEvent()!);
}

export async function getSupabaseUser_() {
  const event = getRequestEvent();
  const supabase = event?.locals.supabase as SupabaseClient;
  const user = await supabase.auth.getUser();
  if (user.data.user) return user.data.user;
  return null;
}

export async function login_(formData: FormData) {
  const email = formData.get("email") as string;
  const password = formData.get("password") as string;
  const search = formData.get("search") as string;
  const parsed = qs.parse(search);

  const event = getRequestEvent();
  const supabase = event?.locals.supabase as SupabaseClient;
  try {
    const user = await supabase.auth.signInWithPassword({ email, password });

    if (user.error) {
      return new Error("Invalid login credentials");
    } else {
      const session = await getSession();
      const jwt = generateJWT(user.data.user.id, email);
      const apiKey = generateJWT(user.data.user.id, email, true);
      await session.update((d) => {
        d.user = user.data.user;
        d.jwt = jwt;
        d.apiKey = apiKey;
      });
      if (parsed.short) {
        const long = await getLongUrl(parsed.short as string);
        return redirect(`/playground/?data=${long}`);
      }
      return redirect("/playground");
    }
  } catch (error) {
    console.log("error", error);
    return new Error("Invalid login credentials");
  }
}

export async function register_(formData: FormData) {
  const name = formData.get("name") as string;
  const email = formData.get("email") as string;
  const password = formData.get("password") as string;
  const confirmPassword = formData.get("confirmPassword") as string;

  if (password !== confirmPassword) {
    return new Error("Passwords do not match. Please try again.");
  }
  const event = getRequestEvent();
  const supabase = event?.locals.supabase as SupabaseClient;
  try {
    const user = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          name,
        },
      },
    });

    if (user.error) {
      return new Error(user.error.message);
    } else {
      const session = await getSession();
      const jwt = generateJWT(user.data.user!.id, email);
      const apiKey = generateJWT(user.data.user!.id, email, true);
      await session.update((d) => {
        d.user = user.data.user;
        d.jwt = jwt;
        d.apiKey = apiKey;
      });
      await session.update((d) => (d.user = user.data.user));
      return redirect("/playground");
    }
  } catch (error) {
    return new Error("Invalid login credentials");
  }
}

export async function googleSignIn_() {
  try {
    const response = await fetch("/api/google-login");
    const data = await response.json();
  } catch (error) {
    console.log(error);
    throw error;
  }
  //   return redirect(credentials.data.url!);
}

export async function signOut_() {
  const event = getRequestEvent();
  const supabase = event?.locals.supabase as SupabaseClient;
  await supabase.auth.signOut();
  const session = await getSession();
  await session.update((d) => {
    d.user = undefined;
    d.jwt = undefined;
  });
  throw redirect("/auth/login");
}

export async function regenerateJWT_() {
  const session = await getSession();
  const user = session.data.user;
  const jwt = generateJWT(user.id, user.email);
  await session.update((d) => {
    d.jwt = jwt;
  });
}
